.account-utilities {
  width: 251px;
  padding: 20px 20px 12px 20px;
  //background: $color-beige;
  a {
    @include l4;
    width: 100%;
    display: block;
  }
  .sidebar-menu {
    &__item {
      a {
        color: $color-gray-light-text;
      }
      &.active {
        &,
        & a {
          color: $color-black;
        }
      }
      &--account {
        @include h20;
        line-height: normal;
        font-size: 16px;
        margin-bottom: 14px;
        padding-bottom: 14px;
        border-bottom: 1px solid $color-gray-border-section;
        color: $color-black;
        a {
          color: $color-black;
        }
      }
      &--details,
      &--orders,
      &--address,
      &--favorites,
      &--artist,
      &--replenish,
      &--skin_type {
        @include h9;
        line-height: 30px;
        text-transform: uppercase;
        @include breakpoint($medium-up) {
          line-height: 30px;
        }
      }
      &--signout {
        @include h21;
      }
      &--picture {
        margin: 10px 0 5px 0;
      }
      &--loyalty {
        display: none;
      }
    }
  }
}

.order-details {
  .account-utilities {
    float: left;
    margin-right: 28px;
  }
  &__item {
    margin-top: 20px;
    h4 {
      padding-bottom: 10px;
      margin-bottom: 20px;
      border-bottom: 1px solid $color-gray-border;
      font-family: $main-font-bold;
    }
  }
  &-page {
    overflow: auto;
    max-width: 1024px;
    margin: $account-indent auto;
    &__content {
      overflow: hidden;
      width: auto;
      border-left: 1px solid $color-gray-border;
      padding-left: 20px;
    }
  }
  .cart-item {
    padding-top: 20px;
    @include breakpoint($medium-up) {
      &__thumb {
        width: 80px;
        margin-right: 10px;
      }
      &__desc {
        float: left;
        width: 250px;
        margin-left: 10px;
      }
      &__qty {
        float: left;
        clear: none;
        padding-top: 0;
        margin: 0 10px;
        width: 14%;
      }
      &__total {
        width: 14%;
        float: left;
        line-height: 36px;
      }
    }
    @include breakpoint($small-down) {
      &__thumb {
        float: none;
      }
      &__desc {
        margin-top: 10px;
      }
    }
  }
  .order-totals {
    &__header {
      @include breakpoint($small-up) {
        padding-bottom: 10px;
        margin-bottom: 5px;
        border-bottom: 1px solid $color-gray-border;
        font-family: $main-font-bold;
      }
      @include breakpoint($medium-up) {
        margin-bottom: 20px;
      }
    }
    @include breakpoint($medium-up) {
      float: right;
      width: 29%;
    }
    tr,
    td,
    th {
      border: 0;
      text-wrap: balance;
    }
    td {
      padding: 0;
    }
  }
}
