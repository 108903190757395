.profile-pic-upload {
  h2 {
    margin: 0 0 20px 0;
    text-align: center;
  }
  p {
    font-size: 14px;
    margin: 0;
    line-height: 24px;
  }
  ul {
    margin: 0 0 0 20px;
    line-height: 24px;
    list-style: disc outside;
  }
  &__progress-wrapper {
    margin: 40px 0;
  }
  &__progress-spinner {
    margin: 0 auto;
  }
}

.upload-file {
  margin: 40px 0;
  position: relative;
  &__button {
    position: relative;
    width: 133px;
    height: 30px;
  }
  #form--profile--field--UPLOADED_FILE {
    position: relative;
    top: 0;
    left: 0;
    width: 133px;
    height: 30px;
    filter: alpha(opacity= 0);
    -moz-opacity: 0;
    opacity: 0;
    z-index: 2;
  }
  #form--profile--field--UPLOADED_FILE:hover {
    cursor: pointer;
  }
  &__imposter {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 11px;
    width: 160px;
    z-index: 1;
  }
  &__value {
    position: absolute;
    top: 0;
    left: 158px;
    margin-left: 30px;
    padding: 8px 0;
    color: $color-black;
    border-color: $color-black;
  }
}
