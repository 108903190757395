$account-divs: '.account-page',
  // landing
  '.address-book-page',
  // address
  '.add-payment-page',
  // add payment
  '.profile-page',
  // profile
  '.past-purchases-page',
  // past purchases
  '.replenishment-page',
  // auto replenishment
  '.sign-in-page',
  // signin
  '.artist-picks-page'; // artist picks

$account-mobile-divs: '.account-mobile-landing-page'; // landing

// account vars
$account-indent: 40px;
$account-indent-header: 40px;
$account-separator: 1px solid $color-gray-border;
$account-alt-heading-separator: 1px solid $color-black;
/// specific account product table/grid column widths ///
$account-created-width: 26%; // column 1
$account-trans-width: 26%; // column 2
$account-price-width: 14%; // column 3
$account-order-width: 22%; // column 4
$account-details-width: 12%; // column 5
$account-product-width: $account-created-width + $account-trans-width; // column 1 + 2

@mixin account-product-column() {
  @include clearfix;
  float: left;
}

// common extended styles
@mixin account-layout {
  @include clearfix;
  max-width: $max-width;
  margin: $account-indent auto;
}

// adding .account__section to unify
// common account styles
.account {
  .account__section {
    @include account-layout;
    @include breakpoint($small-up) {
      margin: 165px auto 40px;
    }
    @if $cr19 == false {
      @include breakpoint($medium-up) {
        margin: 40px auto;
      }
    }
    @if $cr19 == true {
      @include breakpoint($large-up) {
        margin: calc(#{$mobile-nav-height} + #{$mobile-top-offer-height}) auto;
      }
    }
  }
  a.selectBox {
    height: 30px;
  }
}

// account landing
.account-page {
  .account-utilities {
    float: left;
    margin-right: 28px;
  }
}

.account-page__content {
  width: auto;
  overflow: hidden;
  border-left: 1px solid $color-gray-border;
}

.account-page__header {
  @include h19;
  margin: 4px 0 6px;
  border-bottom: 1px solid $color-black;
  text-indent: $account-indent-header;
  padding-bottom: 10px;
  .account-page__header_nobold {
    font-family: $main-font;
  }
}

#account-page__welcome {
  display: none;
}

.account-profile,
.account-orders,
.account-address,
.account-payment,
.account-loyalty {
  position: relative;
  width: 33.33%;
  float: left;
  .section-head {
    position: relative;
  }
  .section-header__header {
    @include h8;
    font-size: 12px;
    text-align: left;
    margin-bottom: 10px;
    letter-spacing: normal;
    border-bottom: 1px solid $color-gray-border-section;
  }
  .section-header__link {
    @include h6;
    text-decoration: none;
    position: absolute;
    text-transform: uppercase;
    right: 0;
    top: 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
  }
  .section-content {
    @include h6;
    font-size: 12px;
    color: $color-gray-light-text;
    h4 {
      font-family: $main-font-bold;
      margin-bottom: 32px;
    }
    .order-detail-item-link {
      visibility: hidden;
    }
    .reorder-item-link {
      text-decoration: none;
      color: $color-black;
    }
  }
}

.account-artists_pick {
  // this was supposed to be a tile, in the account landing page's content area, like the above pieces, but we're hiding now, due to its incompleteness
  display: none;
}

.account-orders,
.account-replenishment {
  border-top: 1px solid $color-gray;
}

.account-profile {
  padding: 30px 2.5% $account-indent $account-indent;
  .account-profile__name,
  .account-profile__email,
  .account-profile__newsletter,
  .account-profile__newsletter-mobile,
  .account-profile__connected-accounts {
    span {
      font-family: $main-font-bold;
    }
  }
}

.account-address {
  padding: 30px 2.5% 40px 0;
  .section-content {
    h4 {
      margin-bottom: 5px;
    }
  }
}

.account-payment {
  padding: 30px 30px 40px 0;
  .section-content {
    h4 {
      margin-bottom: 5px;
      padding-bottom: 0;
    }
    .payment-info__header {
      margin-top: 10px;
    }
  }
}

.account-orders {
  background: url(/media/images/account/bg_account_landing.gif) no-repeat 100% 0;
  width: 61%;
  padding: 30px 20px 40px $account-indent;
  .section-head__header {
  }
}

.account-replenishment {
  width: 69%;
  float: left;
  padding: 30px 30px 40px $account-indent;
}

.account-loyalty {
  display: none;
}

@include breakpoint($medium-down) {
  .account-profile,
  .account-address,
  .account-payment,
  section.account-orders {
    float: none;
    padding-left: 40px;
    width: 100%;
  }
}

// mobile landing
.account-landing-profile__header-container {
  text-align: center;
}

.account-mobile-landing-page {
  .account-landing-profile__header {
    @include h13;
  }
  .account-landing-profile__img {
    width: 154px;
    margin: 0 auto 30px auto;
    a {
      @include link-reset;
    }
  }
  .account-landing-menu {
    border-top: 1px solid $color-gray;
    .account-landing-menu__item {
      a {
        @include link-reset;
        @include h8;
        @include breakpoint($small-up) {
          display: block;
          line-height: 39px;
          padding-left: 20px;
          border-bottom: 1px solid $color-gray;
          font-weight: bold;
        }
        @include breakpoint($medium-up) {
          padding-left: 30px;
        }
      }
    }
  }
}

// Shared styles for the sku_include.mustache template
// (used by both past purchases and favorites)
.past-purchases,
.favorites,
.wishlist,
.artist-picks {
  &-page {
    .product {
      padding-top: 20px;
      @include breakpoint($medium-up) {
        padding-top: 0;
      }
      &-info {
        @include breakpoint($small-down) {
          position: relative;
          padding-left: 35%;
        }
        &-wrapper {
          // this selector is only of use for mobile
          position: relative;
        }
        &__product {
          @include breakpoint($medium-up) {
            @include account-product-column;
            vertical-align: top;
            width: $account-created-width + $account-trans-width;
          }
        }
        &__price {
          margin-top: 3px;
          @include breakpoint($medium-up) {
            @include account-product-column;
            margin-top: 0;
            vertical-align: top;
            width: $account-price-width;
          }
        }
        &__actions {
          @include breakpoint($medium-up) {
            @include account-product-column;
            vertical-align: top;
            width: $account-order-width + $account-details-width;
          }
        }
        &__search {
          display: inline-block;
        }
        &__shade {
          margin-top: 10px;
          &-tile {
            @extend .shade-picker__shade;
            height: 21px;
            width: 21px;
            border: none;
            box-shadow: none;
          }
          &-name {
            display: inline-block;
            vertical-align: top;
            line-height: 21px;
          }
        }
        &__name {
          a {
            @include h12;
            color: black;
            text-decoration: none;
          }
        }
        &__re-order {
          display: none;
        }
      }
      &-image {
        @include breakpoint($small-down) {
          position: absolute;
          top: 10px;
          left: 0;
          width: 30%;
          &__link,
          &__image {
            display: block;
            width: 100%;
          }
        }
        float: left;
      }
    }
  }
}

// to make account overlays white
.account {
  #cboxOverlay {
    background: $color-white;
  }
  #colorbox {
    border: 1px solid $color-gray-border;
  }
  #cboxClose {
    top: 4px;
    right: 4px;
  }
}

.order-details {
  .cart-item__size {
    color: $color-black;
    font-size: 11px;
    @include breakpoint($landscape-up) {
      font-size: 14px;
    }
  }
}

//padding fixes for taller nav
body {
  .sign-in-page,
  .past-purchases-page,
  .address-book-page,
  .profile-page,
  .add-payment-page {
    @include breakpoint($medium-down) {
      margin-top: $mobile-nav-height + $mobile-top-offer-height
    }
  }
}
