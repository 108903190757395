.address-book-page {
  @include clearfix;
  .account-utilities {
    float: left;
    margin-right: 28px;
  }
  &__content {
    width: auto;
    overflow: hidden;
    border-left: 1px solid $color-gray-border;
    .address_data {
      color: $color-gray-light-text;
    }
    .address-book-info {
      h2.section-head {
        margin: 20px 0;
      }
      .controls {
        @include breakpoint($small-up) {
          font-size: 12px;
          font-weight: bold;
          margin: 5px 0;
          text-transform: uppercase;
          a {
            margin-right: 10px;
          }
        }
      }
    }
    .submit {
      display: none;
    }
  }
  &__header {
    .account-page__header_nobold {
      font-family: $main-font;
    }
    @include breakpoint($small-down) {
      @include h13;
    }
    @include breakpoint($medium-up) {
      //@include h19;
      @include h19;
      text-indent: $account-indent-header;
      margin: 4px 0 40px 0;
      padding-bottom: 14px;
      border-bottom: $account-alt-heading-separator;
      clear: both;
    }
  }

  //shared
  .address-book,
  .payment-info {
    @include breakpoint($small-up) {
      margin: 0 20px 20px;
      width: auto;
    }
    @include breakpoint($medium-up) {
      margin: 0 0 40px $account-indent;
      width: 95.43%;
    }
  }
  .address-book .section-head,
  .payment-info .payment-book__header {
    margin: 20px 0 6px 0;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    @include h13;
    @include breakpoint($medium-up) {
      @include h19;
    }
  }
  .address-book__header,
  .payment-info__header {
    @include h8;
    @include breakpoint($small-up) {
      border-bottom: $account-alt-heading-separator;
      font-size: $base-font-size;
      font-weight: bold;
      margin: 30px auto;
      padding: 0;
      text-indent: $account-indent;
    }
    @include breakpoint($medium-up) {
      margin: 0 0 10px 0;
      padding: 0 0 14px;
    }
  }
  .address-book__content,
  .payment-info__content {
    margin-bottom: 14px;
  }
  .address-item__address {
    margin-bottom: 14px;
  }
  .address-item__default-address,
  .payment-item__default-address {
    margin-top: 6px;
  }
  .address-item__edit,
  .address-item__delete,
  .payment-item__edit,
  .payment-item__delete {
    @include l5;
  }
  .address-item__delete,
  .payment-item__delete {
    margin-left: 30px;
  }
  .default-address__button,
  .default-payment__button {
    @include button--secondary;
  }

  // addresses
  .address-book__link {
    @include button;
  }
  .address-item__billing-link {
    margin-top: 25px;
    padding: 20px 0 20px 0;
    border-top: 1px solid $color-gray;
    border-bottom: 1px solid $color-gray;
  }
  .address-item_card {
    float: left;
    width: 27%;
    margin-top: 10px;
    @include breakpoint($portrait-mobile) {
      margin-top: 7%;
    }
  }
  .address-item_info {
    width: 70%;
    float: left;
  }
  .address-book__others {
    margin-top: 14px;
  }

  // payments
  .payment-info__link {
    @include button;
  }
  .payment-item__address,
  .payment-book__other {
    margin-top: 20px;
  }
  .payment-book__other {
    border-bottom: 1px solid $color-gray;
  }
  .payment-book-item__other {
    padding: 0 0 10px 0;
  }
  .payment-item__details {
    padding: 10px 0;
    border-top: 1px solid $color-gray;
    border-bottom: 1px solid $color-gray;
  }
}

#myaccount-wrapper {
  .breadcrumb {
    margin: 5px 20px;
  }
  .address-form {
    h1 {
      @include h13;
      text-indent: 0;
      padding-bottom: 0;
      margin-bottom: 30px;
      border-bottom: none;
    }
    #address_book_address {
      @include breakpoint($small-up) {
        padding: 0 20px 10px;
        input {
          border-radius: 0;
        }
        .country_container {
          select {
            width: 100%;
          }
        }
      }
      @include breakpoint($medium-up) {
        padding: 0;
      }
      .required-note {
        p {
          margin: 0 0 10px;
        }
      }
      .btn_submit {
        text-align: center;
      }
    }
  }
}

#address_form_container {
  border: 1px solid #000;
  padding: 20px;
  #colorbox & {
    border: none;
  }
}

.ship-type-option {
  padding: 0 10px 0 10px;
}

.address-form {
  @include clearfix;
  &__header {
    text-align: left;
    margin: 0 0 15px 10px;
    text-transform: uppercase;
  }
  &__item {
    @include breakpoint($small-up) {
      padding: 10px 0;
    }
    @include breakpoint($medium-up) {
      padding: 10px 20px;
    }
    &.state,
    &.postal_code {
      float: left;
      width: 50%;
    }
    &.required_text {
      padding: 20px;
    }
    &.phone_requirements {
      clear: both;
    }
    &.ship_type,
    &.default_shipping {
      padding: 5px 10px;
      * {
        display: inline-block;
        float: left;
      }
      .field {
        width: auto;
        margin: 2px 5px 5px;
      }
    }
    &.country {
      display: block;
      clear: both;
    }
    .field {
      width: 100%;
      padding: 0px 10px;
    }
    @include breakpoint($small-range) {
      .ship-type-option {
        .field {
          width: auto;
        }
      }
    }
    // @TODO: Does this need to be here? There's a global style for errors in _forms.scss.
    .error {
      border: 1px solid #f00;
      color: #f00;
    }
    #QAS_NODE {
      .address1_container {
        padding: 10px 0 20px;
      }
      select {
        margin-top: 10px;
      }
      .qas_submit_container {
        padding-top: 20px;
      }
    }
  }
  &__submit {
    margin-top: 20px;
  }
  input.form-submit {
    float: right;
    margin-right: $account-indent;
  }
  legend {
    display: none;
  }

  // @TODO: See comment above.
  .error_messages.error li {
    color: #f00;
  }
}

// overlay delete address & card
.address-delete,
.payment-delete {
  &__info {
    margin-bottom: 4px;
  }
  &__link {
    @include l5;
    &--cancel {
      margin-left: 50px;
    }
  }
}

#payment_edit {
  @include breakpoint($small-up) {
    margin: 0 20px;
  }
  @include breakpoint($medium-up) {
    margin: 0 0 0 40px;
  }
}
