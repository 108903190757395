$replenish-column-width: 16%;

@mixin replenish-product {
  .product {
    @include clearfix;
    &__image-link {
      display: block;
      width: 40%;
      padding: 0 10px;
      float: left;
      @include breakpoint($medium-up) {
        padding: 0;
        width: 15%;
      }
    }
    &__info {
      float: right;
      width: 60%;
      @include breakpoint($medium-up) {
        float: left;
        width: 29%;
        padding-left: 10px;
      }
    }
    &__quantity,
    &__frequency {
      display: none;
    }
    &__image {
      width: 100%;
    }
    &__name {
      @include link-reset;
      @include main-font-bold;
      display: block;
      font-size: 11px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }
    &__shade {
      margin-top: 14px;
      .swatch-container {
        float: left;
        margin-right: 10px;
      }
      .shade-picker__shade {
        width: 21px;
        height: 21px;
      }
    }
    &__shade-name {
      line-height: 21px;
    }
    &__price,
    &__next-order-date {
      @include main-font-bold;
      float: right;
      width: 60%;
      margin-top: 12px;
      @include breakpoint($medium-up) {
        float: left;
        width: 28%;
        margin-top: 0;
      }
    }
    &__link {
      display: inline-block;
      @include l5;
      @include breakpoint($medium-up) {
        margin-top: 18px;
      }
    }
  }
}

//index
.replenishment-page {
  @include clearfix;
  .account-utilities {
    float: left;
    margin-right: 32px;
  }
  &__content {
    width: auto;
    overflow: hidden;
    position: relative;
  }
  &__header {
    @include h19;
    text-indent: $account-indent;
    margin-bottom: 0;
  }
}

.replenishment {
  &__text {
    font-size: 16px;
    line-height: 22px;
    padding: 14px $account-indent 14px $account-indent;
    border-bottom: 2px solid $color-black;
  }
  &__products {
    .products-header {
      display: none;
      @include breakpoint($medium-up) {
        @include clearfix;
        display: block;
        margin-top: 38px;
        padding-bottom: 16px;
        border-bottom: 1px solid $color-gray-light;
        &__item {
          float: left;
          @include h24;
          &--info {
            width: 44%;
            text-indent: $account-indent;
          }
          &--price {
            width: 28%;
          }
          &--next {
            width: 28%;
          }
          &--quantity,
          &--last,
          &--frequency {
            display: none;
          }
        }
      }
    }
    .products-list {
      &__list {
      }
      &__item {
        padding: 18px 0 20px 0;
        border-bottom: 1px solid $color-gray-light;
        @include replenish-product;
      }
    }
  }
}

.replenishment-upsell {
  padding: 20px;
  &__link {
    @include l2;
  }
}

//details
.replenishment-detail-page {
  .replenishment-detail-product {
    @include replenish-product;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-gray-light;
    margin-bottom: 20px;
    .js-rep-details {
      display: none;
    }
  }
  padding: 0 20px;
  .shipping-frequency__header,
  .shipping-address__header,
  .payment-info__header {
    @include h24;
    margin-bottom: 10px;
  }
  #replenishment_update {
    @include clearfix;
  }
  .shipping-frequency__info {
  }
  .frequency-table {
    &__table {
    }
    &__cell {
      position: relative;
      padding: 20px 0 40px 0;
      vertical-align: top;
    }
    &__link {
      display: block;
      position: absolute;
      bottom: 20px;
      left: 0;
    }
    .selectBox {
      margin-top: 10px;
    }
  }
  .shipping-address,
  section.payment-info {
    @include breakpoint($medium-up) {
      float: left;
      margin-top: 20px;
    }
  }
  .shipping-address {
    @include breakpoint($medium-up) {
      width: 46%;
    }
    .address-controls {
      &__list,
      &__choose-address {
        margin-top: 10px;
      }
    }
    .selectBox {
      margin-top: 10px;
      width: 75%;
    }
  }
  section.payment-info {
    @include breakpoint($medium-up) {
      width: 54%;
      padding-left: 20px;
    }
    &__submit {
      margin-top: 10px;
    }
    .payment-controls {
      &__list,
      &__method {
        margin-top: 10px;
      }
    }
    .selectBox {
      margin-top: 10px;
      width: 75%;
    }
    .payment-info__submit {
      margin-top: 20px;
    }
  }
}

//details mobile
.replenishment-detail-page__mobile {
  p {
    margin: 0;
  }
  select {
    width: 100%;
    margin-top: 20px;
  }
  .replenishment-detail-page__text {
    margin-bottom: 20px;
  }
  .controls__link {
    margin-bottom: 20px;
    width: 100%;
  }
  @include replenish-product;
  .shipping-frequency {
    margin-top: 30px;
    .order-info,
    .order-freq {
      margin-top: 20px;
    }
  }
  .shipping-address {
    margin-top: 30px;
    .address-form__header {
      @include h24;
      margin-bottom: 10px;
      text-align: left;
    }
    #please_note_changes {
      margin-top: 20px;
    }
  }
  .address-form__required {
    padding: 0 0 0 10px;
    .address-form__item & {
      padding: 0;
    }
  }
  .address-form__section {
    margin-top: 20px;
    select {
      margin-top: 0;
    }
  }
  .payment-method {
    margin-top: 20px;
    .payment-method {
      margin-bottom: 0;
    }
    &__header {
      @include h24;
      margin-bottom: 10px;
    }
    &__info,
    &__billing-info {
      margin-top: 20px;
    }
    &__controls {
      .payment-method__link {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}

.replenishment-upsell {
  margin: 20px;
  overflow: auto;
  padding-left: 0;
  &__text {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.recommendedProd {
  overflow: auto;
  width: 50%;
  .product-thumb__headline {
    height: 42px;
  }
  .link {
    &--inverted {
      border-width: 0 0 2px 0;
      padding: 0 0 5px 0;
    }
  }
  @include breakpoint($small-down) {
    width: 100%;
    text-align: center;
    .product-thumb {
      &__abstract {
        width: 100%;
        padding-left: 0;
        float: left;
        .product-thumb--price {
          margin-bottom: 12px;
        }
      }
      &__image-link {
        float: none;
        margin: 0 auto;
      }
    }
    .link {
      float: none;
    }
  }
}

.replenishment-top-products {
  .section-head__header {
    font-size: 16px;
    margin-top: 20px;
    border-top: solid 1px #ccc;
    padding-top: 20px;
    font-family: $main-font-bold;
  }
}

.touch {
  & .replenishment-upsell {
    &__link {
      padding: 0 0 5px 0;
      border-width: 0 0 2px 0;
    }
  }
}
