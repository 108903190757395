.profile-page {
  @include clearfix;
  .error_messages .server {
  }
  .account-utilities {
    // TODO: Consolidation candidate (see address book)
    float: left;
    margin-right: 28px;
  }
  &__errors {
    li {
      margin-bottom: 14px;
    }
  }
  &__content {
    // TODO: Consolidation candidate
    width: auto;
    overflow: hidden;
    border-left: 1px solid $color-gray-border;
    .breadcrumb {
      margin: 5px 20px;
    }
  }
  &__title_msg {
    // TODO: Consolidation candidate (see address book)
    @include breakpoint($medium-up) {
      border-bottom: 2px solid $color-black;
      margin: 4px 0 17px 0;
      padding-bottom: 16px;
    }
    h1 {
      margin-bottom: 10px;
    }
  }
  &__header {
    // TODO: Consolidation candidate (see address book)
    .account-page__header_nobold {
      font-family: $main-font;
    }
    @include breakpoint($small-down) {
      @include h13;
      margin: 30px auto 20px;
    }
    @include breakpoint($medium-up) {
      //@include h19;
      @include h19;
      text-indent: $account-indent-header;
      margin: 4px 0 40px 0;
      padding-bottom: 14px;
      border-bottom: $account-alt-heading-separator;
      clear: both;
    }
  }
  &__required-container {
    position: absolute;
    top: 210px; /* top: 179px; */
    right: 464px;
    .profile-page & {
      // absolute positioning broken on
      // /account/profile_preferences.tmpl
      // not sure what it is for
      position: static;
      top: 0;
      left: 0;
      margin: 0 0 14px $account-indent;
    }
  }
  legend {
    display: none;
  }
  .form-submit {
    margin: 20px 0 40px 20px;
    float: right;
  }
  .big-button {
    background-color: $color-black;
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  .address-info {
    h3 {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .newsletter-info,
  .sms-promotions {
    @include breakpoint($small-up) {
      margin: 0 20px 20px;
      h2 {
        font-size: 18px;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
    }
    @include breakpoint($medium-up) {
      margin: 0 40px;
      h2 {
        margin-bottom: 40px;
      }
    }
    &__item {
      margin: 0;
      float: left;
      width: 100%;
    }
    a {
      color: $color-black;
    }
  }
}

.address-info,
.profile-info,
.optional-info,
.sms-promotions,
.newsletter-info,
.skin-type-info,
.social-info {
  @include breakpoint($small-up) {
    margin: 0 10px;
  }
  @include breakpoint($medium-up) {
    margin: 0 $account-indent;
  }
  &__header,
  h3 {
    @include h8;
    //text-indent: $account-indent;
    margin: 0 0 20px 0;
    padding-bottom: 5px;
    border-bottom: $account-alt-heading-separator;
    clear: both;
  }
  .field,
  .selectBox-options li {
    @include input-placeholder {
      text-transform: uppercase;
    }
  }
  &__item {
    padding: 0;
    float: left;
  }
  &__item {
    padding: 0;
  }
}

// @TODO: this seems misplaced. Someone please fix.

.pc_sms_promo {
  &__item {
    display: none;
  }
}

.profile-info {
  &__item {
    width: 100%;
    padding: 10px;
    @include breakpoint($medium-up) {
      width: 42.49%;
      padding: 0;
      margin: 0 5% 20px 0;
    }
    .field {
      width: 100%;
    }
    input {
      border-radius: 0;
    }
  }
  &__text {
    margin-bottom: 10px;
  }
  &__fieldset {
  }
  &__required {
    display: none;
  }
  .profile_header_wrapper {
    padding: 10px;
  }
  &__change_pwd_link {
    margin-top: 7%;
  }
}

.optional-info {
  @include breakpoint($small-up) {
    margin: 10px 20px 20px;
  }
  @include breakpoint($medium-up) {
    margin: 0 40px;
    &__item {
      float: none;
    }
  }
  &__birthday {
    &-select {
      display: inline-block;
      a.selectBox {
        width: 100%;
        display: inline-block !important;
      }
    }
    &-label {
      display: block;
    }
    &-select,
    &-text,
    &-label {
      margin: 0 10px 20px 0;
    }
    &-select a.selectBox,
    &-select select {
      min-width: 100px;
    }
  }
  .birthday {
    .optional-info__item {
      width: 30%;
      margin: 0 2% 5px 0;
    }
    select {
      min-width: 80px;
      width: 100%;
    }
  }
}

.newsletter-info {
  @include clearfix;
  &__text {
    &--new,
    &--provid {
      margin-left: 20px;
    }
  }
  @include breakpoint($small-up) {
    #providing-email {
      margin: 0 0 10px;
    }
  }
}

.sms-info {
  &__item {
    margin: 0 0 0 10px;
  }
}

.skin-type-info {
  margin: 10px 20px 20px;
  @include breakpoint($medium-up) {
    margin: 0 40px;
  }
  &__item {
    display: flex;
    flex-wrap: wrap;
    .label {
      display: none;
    }
    input[type='checkbox'] {
      &:checked ~ label::before,
      & ~ label::before {
        vertical-align: text-bottom;
      }
    }
    label {
      flex-basis: 50%;
      @include breakpoint($medium-up) {
        margin-bottom: 15px;
      }
    }
    .checkbox {
      display: inline-flex;
      flex-basis: 50%;
      margin-bottom: 10px;
      @include breakpoint($medium-up) {
        flex-basis: 100%;
        margin-bottom: 15px;
      }
      .field,
      label {
        @include breakpoint($medium-up) {
          margin-bottom: 0;
        }
      }
      label {
        flex-basis: 100%;
      }
    }
  }
}
