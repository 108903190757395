.favorites,
.wishlist {
  &-page {
    @include clearfix;
    .account-utilities {
      float: left;
      margin-right: 32px;
    }
    &__content {
      width: auto;
      overflow: hidden;
      position: relative;
    }
    .product {
      &-info {
        @include clearfix;
        position: relative;
        &__product {
          width: 85%;
          @include breakpoint($medium-up) {
            width: $account-product-width + $account-details-width;
          }
        }
        &__name {
          @include breakpoint($medium-up) {
            padding: 10px;
          }
        }
        &__reviews {
          display: none;
        }
        &__actions {
          @include breakpoint($small-down) {
            margin-top: 10px;
          }
          @include breakpoint($medium-up) {
            width: $account-order-width;
            text-align: right;
          }
        }
      }
      &-image {
        @include breakpoint($medium-up) {
          margin-right: 15px;
          width: 125px;
        }
      }
    }
  }
  &__item {
    margin-left: 20px;
    padding-top: 20px;
    margin-top: 20px;
    border-top: $account-separator;
    &:first-child {
      padding-top: 0;
      margin-top: 0;
      border-top: none;
    }
  }
}

.favorites {
  &-page {
    &__content {
      @include breakpoint($small-up) {
        margin: 0 20px;
        font-size: 12px;
      }
      @include breakpoint($medium-up) {
        margin: 0;
        font-size: 14px;
      }
    }
    &__head {
      @include breakpoint($small-down) {
        text-align: center;
      }
      @include breakpoint($medium-up) {
        position: relative;
        margin: 4px 0 17px 0;
        padding-bottom: 16px;
        border-bottom: 1px solid $color-black;
      }
    }
    &__header {
      @include breakpoint($small-down) {
        @include h13;
        margin-top: 30px;
      }
      @include breakpoint($medium-up) {
        @include h19;
        text-indent: $account-indent;
        margin-bottom: 0;
      }
    }
    &__subheader {
      @include breakpoint($medium-up) {
        margin-top: 15px;
        font-size: 14px;
        text-indent: $account-indent;
      }
    }
    &__tabs {
      @include breakpoint($medium-up) {
        position: absolute;
        top: 1px;
        right: 20px;
        height: 100%;
      }
    }
    &__tab {
      display: inline-block;
      @include h8;
      color: $color-black;
      height: 100%;
      border-right: 1px solid $color-black;
      border-bottom: 1px solid $color-black;
      padding: 8px 40px;
      background-color: $color-beige;
      cursor: pointer;
      @include breakpoint($small-down) {
        width: 50%;
        &:last-child {
          border-right: none;
        }
      }
      @include breakpoint($medium-up) {
        &:first-child {
          border-left: 1px solid $color-black;
        }
      }
      &.active {
        background-color: $color-white;
        border-bottom: none;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__instruction {
    &-step {
      @include breakpoint($small-up) {
        margin: 15px 0;
      }
      @include breakpoint($medium-up) {
        margin: 60px 0;
      }
      &-number,
      &-title {
        text-align: center;
      }
      &-number {
        margin-bottom: 20px;
        padding-left: 5px;
        color: $color-pink;
      }
      &-title {
        @include breakpoint($small-up) {
          font-size: 20px;
          margin-bottom: 10px;
        }
        @include breakpoint($medium-up) {
          font-size: 30px;
          margin-bottom: 20px;
        }
      }
      &-desc {
        width: 65%;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
  &-mylists {
    &__head {
      display: none;
    }
    &__lists {
      &-create-button {
        margin: 18px 0 50px;
        border: 1px solid $color-black;
        cursor: pointer;
        &::before {
          content: '';
          width: 14px;
          height: 14px;
          background: url('#{$base-theme-path}img/icons/src/plus.svg') no-repeat;
          background-size: cover;
          display: block;
        }
        .favorites.all & {
          float: left;
          margin-bottom: 35px;
        }
        @include breakpoint($small-down) {
          display: none;
        }
      }
    }
  }
  &__order-by {
    @include breakpoint($small-down) {
      width: 88%;
      margin: 25px auto;
      .favorites.all & {
        display: block;
        border-left: none;
        border-right: none;
      }
    }
    @include breakpoint($medium-up) {
      float: right;
      margin-top: 18px;
      min-width: 160px;
    }
  }
  &__list {
    clear: both;
    padding-bottom: 20px;
  }
  &.all,
  &-mylists .my-lists__list-content {
    @include breakpoint($small-down) {
      padding-top: 75px;
      position: relative;
    }
  }
  &__recommended-products {
    @extend .recommended-product-items;
    margin-top: 40px;
    button {
      height: 50px;
    }
  }
  &__recommended-products-slide {
    padding: 0 2em;
  }
  &__recommended-product {
    @extend .recommended-item;
    margin-right: 0 !important;
    width: 100% !important;
    @include breakpoint($medium-up) {
      padding: 0 1em !important;
      width: 33.3% !important;
    }
    &-actions {
      margin-top: 10px;
      text-align: right;
    }
    &-add-to-cart {
    }
    &-add-to-favorites {
      color: $color-black;
      font-size: 22px;
      margin-right: 10px;
      &,
      &:hover {
        text-decoration: none;
      }
      &:hover {
        color: $color-pink;
      }
    }
    .product_name {
      min-height: 45px;
      a {
        @include breakpoint($small-down) {
          border: none !important;
          padding-left: 0 !important;
        }
      }
    }
    .size,
    .shade {
      float: left;
    }
    .shade * {
      float: left;
    }
    .swatch-container {
      position: relative;
      top: -1px;
      margin-right: 8px;
    }
  }
  &__sample-list {
    margin-top: 40px;
    padding: 10px 0;
    border-top: $account-separator;
    border-bottom: $account-separator;
  }
}

.favorite-actions {
  position: absolute;
  top: 50px;
  left: $account-created-width + $account-trans-width + $account-price-width + $account-details-width;
  width: $account-order-width;
  text-align: right;
  &__link {
    display: block;
    &--add {
      // use the "plus" icon, use the before pseudo selector for the actual icon, signify that this isn't a media
      // query, don't use the text replace option, size the icon at 10px/pt, take the color of the containing
      // element, and change the vertical-align property to middle
      margin-bottom: 15px;
      &::before {
        content: '';
        width: 10px;
        height: 10px;
        background: url('#{$base-theme-path}img/icons/src/plus.svg') no-repeat;
        background-size: cover;
        display: block;
      }
      .my-lists & {
        display: none; // hide this link when the product it's attached to appears within the my lists section
      }
    }
    &--remove {
      color: $color-gray;
      &,
      &:hover {
        text-decoration: none;
      }
      // use the "circle_close" icon, use the before pseudo selector for the actual icon, signify that this isn't a media
      // query, use the text replace option, and size the icon at 18px/pt
      &::before {
        content: '';
        width: 24px;
        height: 24px;
        background: url('#{$base-theme-path}img/icons/src/circle_close.svg') no-repeat;
        background-size: cover;
        display: block;
      }
    }
  }
}

.wishlist {
  &-page {
    &__header {
      @include h8;
      text-indent: $account-indent;
      margin: 0 0 10px 0;
      padding-bottom: 14px;
      border-bottom: $account-alt-heading-separator;
    }
  }
}

.my-lists {
  &__list {
    @include breakpoint($small-down) {
      border-bottom: $account-separator;
    }
    @include breakpoint($medium-up) {
      margin: 0 0 1px;
      background-color: $color-beige;
    }
    &-header {
      @include breakpoint($small-down) {
        padding: 20px 30px;
        .expanded & {
          border-bottom: $account-separator;
        }
      }
      @include breakpoint($medium-up) {
        padding: 24px 15px;
      }
      &-link {
        @include h8;
        color: $color-black;
        @include breakpoint($small-down) {
          width: 100%;
          display: block;
          padding-right: 20px;
          position: relative;
          &::after {
            content: '';
            width: 17px;
            height: 17px;
            background: url('#{$base-theme-path}img/icons/src/arrow_right.svg') no-repeat;
            background-size: cover;
            display: block;
          }
        }
        @include breakpoint($medium-up) {
          &::before {
            content: '';
            width: 17px;
            height: 17px;
            background: url('#{$base-theme-path}img/icons/src/play.svg') no-repeat;
            background-size: cover;
            display: block;
          }
        }
        &,
        &:hover {
          text-decoration: none;
        }
      }
    }
    &-subtitle {
      &--empty {
        display: none;
        .empty & {
          display: block;
        }
      }
    }
    &-items {
      @include breakpoint($small-down) {
        padding-bottom: 20px;
      }
    }
    &-content {
      margin-top: 15px;
      display: none;
      .expanded & {
        display: block;
      }
    }
    &-actions {
      @include clearfix;
      @include breakpoint($small-down) {
        position: absolute;
        top: 30px;
        left: 6%;
      }
      @include breakpoint($medium-up) {
        margin: 0 0 50px;
        .favorites.all & {
          float: left;
          clear: left;
          margin-bottom: 30px;
        }
      }
    }
    & &-actions {
      @include breakpoint($small-down) {
        width: 88%;
        top: 10px;
      }
    }
    &-image {
      display: none;
    }
    &-link {
      @include link-reset;
      &:hover {
        color: $base-link-color;
      }
      &--print {
        display: inline-block;
        width: 36px;
        height: 40px;
        cursor: pointer;
        &::before {
          content: '';
          width: 36px;
          height: 36px;
          background: url('#{$base-theme-path}img/icons/src/print.svg') no-repeat;
          background-size: cover;
          display: block;
        }
      }
      &--email {
        &::before {
          content: '';
          width: 26px;
          height: 26px;
          background: url('#{$base-theme-path}img/icons/src/email.svg') no-repeat;
          background-size: cover;
          display: block;
        }
        display: inline-block;
        width: 36px;
        height: 40px;
        letter-spacing: -0.3em;
        cursor: pointer;
      }
      &--share {
        @include breakpoint($small-down) {
          @include link($color-pink, $color-black);
        }
        @include breakpoint($medium-up) {
          display: inline-block;
          width: 36px;
          height: 40px;
          cursor: pointer;
          &::before {
            content: '';
            width: 26px;
            height: 26px;
            background: url('#{$base-theme-path}img/icons/src/share.svg') no-repeat;
            background-size: cover;
            display: block;
          }
        }
      }
      &--remove {
        .favorites.all & {
          display: none;
        }
        @include breakpoint($medium-up) {
          display: inline-block;
          position: relative;
          top: 5px;
          margin-left: 40px;
          cursor: pointer;
        }
      }
      &--add-all {
        @include link($color-pink, $color-black);
        @include breakpoint($medium-up) {
          @include h8;
          float: right;
          .favorites.all & {
            clear: right;
            margin-bottom: 50px;
          }
        }
        @include breakpoint($small-down) {
          position: absolute;
          top: 30px;
          right: 6%;
          background-color: $color-pink;
          color: $color-white;
          border-color: $color-pink;
          letter-spacing: 0.18em;
          .my-lists & {
            position: static;
            float: right;
          }
        }
      }
      &--manage {
        display: none;
      }
    }
    &.expanded {
      background-color: transparent;
    }
  }
}

.create-wishlist {
  &__header {
    display: none;
  }
  &__name {
    display: inline-block;
    width: 68%;
    input {
      width: 100%;
    }
  }
  &__icon-select {
    display: none;
  }
}

.add-to-list,
.create-list,
.confirm-delete {
  display: none;
  #colorbox & {
    display: block;
  }
}

.add-to-list {
  #colorbox & {
    margin-top: 30px;
    &__header {
      border-bottom: none;
      margin-bottom: 10px;
      padding-bottom: 0;
      text-align: center;
    }
    &__lists {
      @include clearfix;
      width: 100%;
      margin: 0 auto;
    }
    &__item {
      display: block;
      float: left;
      width: 48%;
      margin: 4px 1%;
      border: 1px solid $color-light-gray;
      padding: 4px 8px;
      color: $color-black;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
