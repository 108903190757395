.account-past-purchases {
  #nav-account {
    display: none;
  }
}

.past-purchases {
  &__content {
    .past-purchases {
      padding-left: 20px;
      .product-item {
        padding-bottom: 30px;
        &__sub-line {
          display: none;
        }
      }
    }
  }
  &-page {
    @include clearfix;
    .account-utilities {
      float: left;
      margin-right: 28px;
    }
    &__header-container {
      text-align: center;
      @include breakpoint($medium-up) {
        text-align: left;
      }
    }
    &__header {
      .account-page__header_nobold {
        font-family: $main-font;
      }
      @include breakpoint($small-down) {
        @include h13;
      }
      @include breakpoint($medium-up) {
        @include h19;
        text-indent: $account-indent-header;
        margin: 4px 0 40px 0;
        padding-bottom: 14px;
        border-bottom: $account-alt-heading-separator;
        clear: both;
      }
    }
    .past-purchases-page__no_orders {
      margin-left: $account-indent;
    }
    .product {
      @include breakpoint($small-down) {
        &:first-child {
          .product-info {
            border-top: none;
          }
        }
      }
      &-info {
        @include clearfix;
        margin-top: -1px;
        padding-top: 15px;
        margin-bottom: 15px;
        border-top: 1px $color-gray-border solid;
        &__reviews__link {
          text-transform: uppercase;
        }
        &__link {
          &.button {
            text-decoration: none;
            margin: 15px 0;
            @include breakpoint($medium-up) {
              margin: 0 0 15px 0;
            }
          }
        }
        &___rating-image {
          width: 60px;
          height: 12px;
        }
        &__add-to-bag {
          display: none;
        }
        &__re-order {
          display: inline;
        }
      }
      &-image {
        @include breakpoint($medium-up) {
          width: 80px;
          height: 110px;
          margin-right: 15px;
        }
      }
    }
  }
  &-data {
    &-header {
      //past-purchases-data-header
      display: none;
      @include breakpoint($medium-up) {
        display: block;
        @include clearfix;
        padding: 0 20px 15px;
        margin: 15px 0 0;
        border-bottom: 1px $color-gray-border solid;
        &__item {
          @include h24;
          text-transform: uppercase;
        }
      }
    }
    &-item {
      //past-purchases-data-header-item
      @include clearfix;
      position: relative;
      padding: 15px 20px;
      margin: 0;
      @include breakpoint($medium-up) {
        border-bottom: 1px $color-gray-border solid;
      }
      &__title {
        font-family: $main-font;
        @include breakpoint($medium-up) {
          display: none;
        }
      }
      &__item {
        //past-purchases-data-header-item__item
        @include breakpoint($small-down) {
          text-indent: 10px;
        }
        &.created-date {
          @include breakpoint($small-down) {
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            text-indent: 10px;
            border-top: 1px $color-gray-border solid;
            border-bottom: 1px $color-gray-border solid;
            margin-bottom: 18px;
          }
        }
        &.price {
          font-family: $main-font;
          font-weight: 400;
        }
        &.view-details {
          @include breakpoint($small-down) {
            position: absolute;
            top: 15px;
            right: 30px;
            line-height: 38px;
          }
          .view-details-button {
            @include breakpoint($small-down) {
              line-height: 40px;
              text-indent: 0;
              .open {
                display: none;
              }
              .active & {
                .open {
                  display: inline;
                }
                .closed {
                  display: none;
                }
              }
            }
            @include breakpoint($medium-up) {
              // use the "plus" icon, use the before pseudo selector for the actual icon, signify that this isn't a media
              // query, use the text replace option, and size the icon at 18px/pt
              display: block;
              height: 20px;
              color: black;
              text-decoration: none;
              &::before {
                content: '';
                width: 18px;
                height: 18px;
                background: url('#{$base-theme-path}img/icons/src/plus.svg') no-repeat;
                background-size: cover;
                display: block;
                float: right;
              }
              .open,
              .closed {
                display: none;
              }
              .active & {
                &::before {
                  content: '';
                  width: 18px;
                  height: 18px;
                  background: url('#{$base-theme-path}img/icons/src/minus.svg') no-repeat;
                  background-size: cover;
                  display: block;
                  float: right;
                }
              }
            }
          }
        }
      }
    }
    &-item-details {
      @include breakpoint($small-down) {
        padding: 0 20px 20px 20px;
      }
      margin-top: -1px; // to make border top of this box flush with the item information above it, so we don't get in appearance a double border
      position: relative;
      &__item {
        padding: 0 20px 10px;
        margin-bottom: -1px;
        border: 1px $color-gray-light solid;
        width: 100%;
      }
      .caret {
        @include breakpoint($small-down) {
          width: 50%;
        }
        position: absolute;
        background: url(/media/images/account/arrow_orders.png) no-repeat top center;
        top: -11px;
        z-index: 10;
        left: $account-created-width;
        width: $account-trans-width/2;
        height: 12px;
        //text-indent: 20px;
      }
      .close,
      .product-footer,
      .order-header,
      .order-details,
      .product-header {
        display: none;
      }
    }
    &-header, //past-purchases-data-header
    &-item, //past-purchases-data-item
    &-item-details {
      //past-purchases-data-header-item-details
      &__item {
        @include breakpoint($medium-up) {
          @include clearfix;
          float: left;
          &.created-date {
            width: $account-created-width;
          }
          &.trans-id {
            width: $account-trans-width;
          }
          &.price {
            width: $account-price-width;
          }
          &.order-status {
            width: $account-order-width;
          }
          &.view-details {
            width: $account-details-width;
          }
        }
      }
    }
    &-header {
      &__item.order-status {
        width: $account-order-width + $account-details-width;
      }
      &__item.view-details {
        display: none;
      }
    }
  }
  &__content {
    width: auto;
    overflow: hidden;
    border-left: 1px solid $color-gray-border;
    min-height: 450px;
  }
  &__shopping {
    margin: 30px 0 0 20px;
  }
}

.order-details-page {
  &__head {
    margin-bottom: 10px;
  }
  &__header {
    @include breakpoint($small-up) {
      margin-bottom: 20px;
    }
    @include breakpoint($medium-up) {
      margin-bottom: 40px;
    }
  }
  .order-totals {
    &__title {
      @include h24;
      text-indent: 0;
      margin: 4px 0 10px 0;
      padding-bottom: 17px;
      border-bottom: 1px solid $color-black;
    }
  }
}
