.add-payment-page {
  @include clearfix;
  .account-utilities {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
      float: $ldirection;
      margin-#{$rdirection}: 28px;
    }
  }
  &__content {
    width: auto;
    overflow: hidden;
    border-left: 1px solid $color-gray-border;
    .payment-item__details {
      color: $color-gray-light-text;
    }
  }
  &__header {
    .account-page__header_nobold {
      font-family: $main-font;
    }
    @include breakpoint($small-down) {
      @include h13;
    }
    @include breakpoint($medium-up) {
      @include h19;
      text-indent: $account-indent-header;
      margin: 4px 0 40px 0;
      padding-bottom: 14px;
      border-bottom: $account-alt-heading-separator;
      clear: both;
    }
  }
  .payment-page__required-container {
    margin-left: 20px;
  }
  .payment-book {
    margin: 0 0 40px $account-indent;
    width: 95.43%;
    &__header {
      margin: 20px 0 6px;
      font-size: 15px;
      font-weight: bold;
    }
    .payment-info {
      .edit-payment,
      .delete-payment {
        @include l5;
      }
      .payment-item__default-address {
        @include button--secondary;
      }
    }
  }
  &__subheader,
  &__sub-header {
    @include h8;
  }
  .payment_method &__subheader {
    @include h8;
    border-bottom: 1px solid $color-black;
    border-top: 1px solid $color-gray-light;
    padding: 15px 0;
    margin-bottom: 7px;
  }
  .payment-book__button {
    border-bottom: 1px solid #ccc;
    margin: 30px 0 23px;
    padding-bottom: 20px;
  }
  .payment_address {
    .required-note {
      p {
        margin: 0 0 10px;
      }
    }
    .address_display_container {
      color: #999;
      font-size: 13px;
    }
  }
  .payment_method {
    .expiration_date_container {
      margin: 10px 0;
      select {
        display: inline-block;
      }
    }
    .cv_number_field {
      border-radius: 0;
      width: 100%;
    }
    .default_payment_container {
      margin: 10px 0;
      label {
        color: #000;
        font-family: $main-font;
        font-size: 13px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
  .payment_method,
  .payment_address {
    padding-bottom: 29px;
    h2 {
      margin-bottom: 5px;
    }
    h2,
    h3 {
      font-family: $main-font;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
    label {
      @include h8;
      display: block;
      color: #999;
      font-family: $main-font;
      font-size: 14px;
      letter-spacing: initial;
      line-height: 20px;
      text-transform: none;
    }
  }
  @media screen and (max-width: 780px) {
    .payment_address {
      .address_controls {
        width: 90%;
        select {
          width: 100%;
        }
      }
    }
  }
}

.payment-form,
.payment-address {
  width: auto;
  margin-left: 20px;
  &__header {
    margin: 15px 0;
    font-size: $base-font-size;
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }
}

.payment-form {
  width: auto;
  &__header {
    @extend .address-form__header;
    text-transform: none;
    @include breakpoint($small-up) {
      @include h13;
      .add-payment-page & {
        text-indent: 0;
        padding-bottom: 0;
        margin-bottom: 30px;
        border-bottom: none;
      }
    }
  }
  &__item {
    padding: 7px 0;
    label {
      @include h8;
      display: block;
      color: #999;
      font-family: $main-font;
      font-size: 13px;
      letter-spacing: initial;
      line-height: 20px;
      text-transform: none;
    }
    &.card_type_container {
      @include breakpoint($medium-up) {
        width: 40%;
      }
    }
    &.expires {
      width: 50%;
    }
    .field {
      width: 100%;
    }
    &.expiration_date_container p {
      display: inline;
      position: relative;
      bottom: 10px;
    }
    &.cvn_payment_container input {
      // width: 63px;
    }
    input {
      @include breakpoint($small-up) {
        border-radius: 0;
        width: 100%;
      }
      @include breakpoint($medium-up) {
        width: auto;
      }
    }
  }
  .card_type_container,
  .card_number_container,
  .expiration_date_container,
  .cvn_payment_container,
  .default_payment_container {
    padding: 15px 0 0;
  }
  .expiration_date_container {
    select {
      @include breakpoint($small-up) {
        display: inline-block;
        width: 30%;
      }
      @include breakpoint($medium-up) {
        width: auto;
      }
    }
  }
  .submit {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  &__submit {
    margin: 20px 0 0 20px;
  }
}

.payment-address {
  &__controls {
    margin: 0 0 20px;
  }
  &__link {
    @include button--secondary;
    margin: 0 0 10px;
    &:hover {
      text-decoration: none;
    }
  }
  &__choose-address {
    .selectbox {
      @include swap_direction(padding, 0 25px 0 10px);
      width: 100%;
      font-size: 13px;
      height: 30px;
    }
  }
}

.payment {
  &-info {
    &-page {
      @extend .address-book-page;
      .grid_container {
        h3 {
          color: $color-black;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0.1em;
          margin: 10px 0 5px;
          text-transform: uppercase;
        }
        .column {
          .credit_card {
            color: #999;
            font-size: 13px;
          }
          .controls {
            font-size: 11px;
            font-weight: bold;
            margin: 5px 0;
            text-transform: uppercase;
            .delete-payment {
              margin-left: 10px;
            }
          }
        }
        .submit {
          display: none;
        }
      }
    }
    &__header {
      @include breakpoint($small-down) {
        @include h13;
        .payment-info-page & {
          // do some resets of the imported styles from above, for mobile
          text-indent: 0;
          padding-bottom: 0;
          margin-bottom: 30px;
          border-bottom: none;
        }
      }
      @include breakpoint($medium-up) {
        //@include h19;
        @include h19;
        //text-indent: $account-indent;
        margin: 34px 0 0;
        padding-bottom: 16px;
        border-bottom: none;
        font-size: 14px;
      }
    }
    &__button {
      @include breakpoint($small-down) {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
          margin-top: 10px;
        }
      }
    }
  }
  &-form {
    @include breakpoint($small-up) {
      padding: 0 20px 10px;
      margin-left: 0;
    }
    @include breakpoint($medium-up) {
      padding: 0 40px 10px;
    }
    .payment_address {
      .required-note {
        p {
          margin: 0 0 5px;
        }
      }
    }
    &__subheading {
      font-family: $main-font;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
    &__address-display {
      color: #999;
      font-size: 14px;
    }
    &__item {
      @include breakpoint($small-up) {
        .selectbox {
          white-space: normal;
          width: 100%;
        }
        .card_number_field {
          width: 100%;
        }
      }
      @include breakpoint($medium-up) {
        .card_number_field {
          width: 40%;
        }
      }
      &.expiration_date_container a {
        display: inline-block !important;
        width: 20%;
        float: left;
        margin-right: 10px;
        margin-bottom: 7px;
      }
      &.cvn_payment_container {
        clear: both;
      }
      .seperator {
        margin: 0 10px;
        float: left;
        line-height: 37px;
      }
    }
  }
}
