.sign-in-page {
  @include breakpoint($small-up) {
    min-height: 350px;
    padding: 20px;
  }
  @include clearfix;
  padding: 30px;
  @include breakpoint($medium-up) {
    max-width: $max-width;
    padding: 0;
    margin: 40px auto;
  }
  &__container {
    @include breakpoint($medium-up) {
      width: 100%;
    }
  }
  .section-head {
    @include breakpoint($small-down) {
      text-align: center;
    }
  }
  &__header {
    @include breakpoint($small-down) {
      @include h13;
      margin-bottom: 16px;
    }
    @include breakpoint($medium-up) {
      @include h19;
      text-indent: $account-indent;
      margin-top: 4px;
      padding-bottom: 16px;
      border-bottom: 2px solid $color-black;
    }
  }
  &__login {
    @include h26;
    height: 50px;
    line-height: 50px;
    background: none;
    border-bottom: 2px solid $color-black;
  }
  &__label {
    @include hidden;
  }
  &__submit {
  }
  input[type='submit'].sign-in-page__submit,
  input[type='button'].sign-in-page__submit {
    width: 100%;
    margin-bottom: 20px;
    @include breakpoint($medium-up) {
      float: left;
      width: auto;
    }
  }
  &__indent {
    //padding-left: 20px;
  }
  .section-head__header {
    @include breakpoint($medium-up) {
      @include h24;
      height: 35px;
      line-height: 50px;
      margin-bottom: 10px;
    }
  }
  .error_messages {
    li {
      margin: 10px 0;
    }
  }
  .new-account__item,
  .return-user__item {
    margin-bottom: 20px;
    input {
      @include breakpoint($small-up) {
        border-radius: 0;
      }
    }
  }
  .new-account {
    &__item {
      &.show_password,
      &.email_promotions,
      &.privacy_policy {
        label {
          display: block;
          visibility: visible;
        }
      }
    }
  }
}

///
/// Layout of signin panel
///
.account__new-account,
.account__return-user {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
    position: relative;
    width: 47.3%;
    min-height: 350px;
    padding-bottom: 30px; // for button
    margin: 0 0 38px 0;
  }
  input[type='text'],
  input[type='password'] {
    display: block;
    width: 100%;
  }
  input[type='text'] {
    margin-top: 10px;
    @include breakpoint($medium-up) {
      margin-top: 0;
    }
  }
  input[type='password'] {
    margin-bottom: 20px;
    @include breakpoint($medium-up) {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }
}

.account__new-account {
  @include breakpoint($medium-up) {
    float: right;
    margin-bottom: 20px;
  }
  .new-account__has-account {
    margin-bottom: 10px;
  }
  input[type='submit'].sign-in-page__submit {
    @include breakpoint($medium-up) {
      float: right;
    }
  }
  .new-account__fieldset {
    label {
      font-size: 12px;
    }
  }
}

.account__return-user {
  @include breakpoint($medium-up) {
    float: left;
    margin-bottom: 20px;
    width: 50%;
    border-right: 1px solid $color-gray-border;
    padding-right: 0;
  }
  #checkout_signin-submit {
    margin-top: 20px;
    @include breakpoint($medium-up) {
      margin-top: 0;
    }
  }
  .return-user__link {
    @include breakpoint($small-down) {
      display: block;
      margin-bottom: 20px;
    }
  }
  .return-user__text {
    @include breakpoint($small-down) {
      margin-bottom: 10px;
    }
  }
  .return-user__item {
    @include breakpoint($medium-up) {
      width: 35%;
      float: left;
      margin-right: 10px;
    }
    a {
      font-size: 12px;
    }
  }
}

//mobile specific
.sign-in-page--mobile {
  .required_mark,
  .required,
  .ecard_lbl,
  .return-user__label,
  label {
    display: none;
    visibility: hidden;
  }
}
