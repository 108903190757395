.artist-picks-page,
.chat-history-page {
  @include clearfix;
  @include breakpoint($small-down) {
    #nav-account {
      display: none;
    }
  }
  .account-utilities {
    float: left;
    margin-right: 32px;
  }
  &__content {
    width: auto;
    overflow: hidden;
    @include breakpoint($small-down) {
      text-align: center;
    }
  }
  &__header {
    @include breakpoint($small-down) {
      @include h13;
    }
    @include breakpoint($medium-up) {
      @include h19;
      margin: 4px 0 6px 0;
    }
  }
  &__subheader {
    // TODO: Consolidation candidate (see address book)
    @include breakpoint($small-down) {
      @include h13;
    }
    @include breakpoint($medium-up) {
      //@include h19;
      @include h19;
      text-indent: $account-indent;
      margin: 22px 0 10px 0;
      padding-bottom: 25px;
      border-bottom: 1px solid $color-black;
      font-size: 14px;
    }
  }
  &__chats {
    @include breakpoint($small-down) {
      text-align: left;
      border-top: 1px solid $color-gray;
    }
  }
  &__no-chats {
    padding: 65px 0;
    text-align: center;
    .no-transcripts {
      &__header {
        font-family: $main-font-bold;
        letter-spacing: 0.2em;
      }
      &__image {
        display: block;
        margin: 0 auto 35px;
      }
      &__link {
        @include button--secondary;
      }
    }
  }
  &__text {
    width: 98.445%;
    padding-bottom: 15px;
    border-bottom: 1px solid $color-black;
    @include breakpoint($small-down) {
      display: none;
    }
  }
  &__products {
    margin-bottom: 25px;
    border-bottom: 1px solid $color-gray;
    min-height: 400px;
    @include breakpoint($small-down) {
      border-top: 1px solid $color-gray;
      min-height: auto;
    }
    .recommended-products__links {
      &-add-all {
        @include link($color-pink, $color-black);
        @include h8;
        float: right;
        margin-bottom: 12px;
        @include breakpoint($small-down) {
          display: none;
        }
      }
    }
    .recommended-products__filters {
      margin: 10px 0 25px;
      @include breakpoint($small-down) {
        display: none;
      }
      .recommended-products__header {
        margin: 5px 12px 0 0;
        float: left;
        @include breakpoint($small-down) {
          display: none;
        }
      }
      .recommended-products__list {
        &-item {
          width: auto;
          border-top: 1px solid $color-black;
          border-bottom: 1px solid $color-black;
          border-right: 1px solid $color-black;
          padding: 5px 15px;
          color: $color-gray;
          text-transform: uppercase;
          font-family: $main-font-bold;
          &:first-child {
            border-left: 1px solid $color-black;
          }
          &.active {
            color: $color-black;
            cursor: pointer;
            &.selected {
              background-color: $color-black;
              color: $color-white;
            }
          }
        }
      }
    }
    // Sku styles for top filter area only.
    .recommended-products__products {
      clear: both;
      padding: 1px 0 0;
      .product {
        position: relative;
        &:first-child {
          .product-info {
            border-top: none;
          }
        }
        &.active {
          display: block;
        }
        &.first-item {
          .product-info {
            border-top: none;
          }
        }
        &-info {
          @include clearfix;
          margin-top: -1px;
          padding-top: 15px;
          border-top: 1px solid $color-black;
          @include breakpoint($small-down) {
            border-top: 1px solid $color-gray;
          }
          &__link {
            &.button {
              text-decoration: none;
              margin: 0;
            }
          }
          &___rating-image {
            width: 60px;
            height: 12px;
          }
          &__add-to-bag {
            display: inline;
          }
          &__re-order {
            display: none;
          }
          &__product {
            width: 65%;
          }
          &__price {
            width: 15%;
          }
          &__actions {
            width: 20%;
          }
        }
        &-image {
          @include breakpoint($medium-up) {
            width: 80px;
            height: 110px;
            margin-right: 15px;
          }
        }
      }
    }
    .recommended-actions {
      position: absolute;
      top: 75px;
      left: $account-created-width + $account-trans-width + $account-price-width + $account-details-width;
      width: $account-order-width;
      text-align: right;
      a {
        color: $color-gray;
        &,
        &:hover {
          text-decoration: none;
        }
        // use the "circle_close" icon, use the before pseudo selector for the actual icon, signify that this isn't a media
        // query, use the text replace option, and size the icon at 18px/pt
        &::before {
          content: '';
          width: 24px;
          height: 24px;
          background: url('#{$base-theme-path}img/icons/src/circle_close.svg') no-repeat;
          background-size: cover;
          display: block;
        }
      }
    }
    // Mobile specific.
    .recommended-products__category {
      .recommended-products__header {
        position: relative;
        @include breakpoint($small-down) {
          text-align: left;
          padding: 15px 0 15px 35px;
          font-family: $main-font-bold;
          border-top: 1px solid $color-gray;
          width: 100%;
        }
      }
      .recommended-products__products {
        position: relative;
        &--list {
          @include breakpoint($small-down) {
            border-top: 1px solid $color-gray;
          }
          & .product-item {
          }
        }
        &--caret {
          @include breakpoint($small-down) {
            width: 50%;
            position: absolute;
            background: url(/media/images/account/arrow_orders.png) no-repeat top center;
            top: -10px;
            z-index: 10;
            left: 9%;
            width: $account-trans-width/2;
            height: 12px;
          }
        }
      }
      &:first-child {
        .recommended-products__header {
          @include breakpoint($small-down) {
            border-top: none;
          }
        }
      }
    }
  }
  .transcript {
    font-size: 12px;
    padding-top: 8px;
    @include breakpoint($medium-up) {
      padding-top: 0;
    }
    &__header {
      @include clearfix;
      height: 51px;
      width: 100%;
      position: relative;
      padding: 8px 0;
      border-top: 1px solid $color-black;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      &:hover {
        cursor: pointer;
      }
      &:first-child {
        border-top: none;
        @include breakpoint($small-down) {
          border-top: none;
        }
      }
      &:last-of-type {
        border-bottom: 1px solid $color-black;
        &.expanded {
          border-bottom: none;
        }
      }
      &--date {
        float: none;
        padding: 0 25px;
        width: 95%;
        letter-spacing: 0.18em;
        display: block;
      }
      @include breakpoint($medium-up) {
        padding: $cart-spacing 0;
        &--date {
          float: left;
          padding: 0 3px;
          width: 34%;
        }
      }
      &--icon {
        float: left;
        font-size: 25px;
        @include breakpoint($small-down) {
          margin: 0 12px;
        }
      }
      &--consultant {
        width: 58%;
        padding-top: 0;
        float: left;
        clear: none;
        text-align: left;
        position: relative;
        vertical-align: middle;
        @include breakpoint($small-down) {
          display: none;
        }
        .consultant-thumb {
          float: left;
          height: 35px;
          vertical-align: middle;
        }
        .your-chat-with {
          display: block;
          clear: none;
          vertical-align: middle;
          margin: 0 0 0 35px;
          @include breakpoint($medium-up) {
            padding: 0 15px 0 0;
            margin: 0 0 0 15px;
          }
        }
      }
      &.expanded {
        a.view-details-button {
          &::before {
            content: '';
            width: 18px;
            height: 18px;
            background: url('#{$base-theme-path}img/icons/src/minus.svg') no-repeat;
            background-size: cover;
            display: block;
          }
        }
      }
      &--view-details {
        @include breakpoint($small-down) {
          position: absolute;
          top: 9px;
          right: 30px;
          line-height: 38px;
        }
        .view-details-button {
          @include breakpoint($small-down) {
            line-height: 40px;
            text-indent: 0;
            .open {
              display: none;
            }
            .active & {
              .open {
                display: inline;
              }
              .closed {
                display: none;
              }
            }
          }
          // use the "plus" icon, use the before pseudo selector for the actual icon, signify that this isn't a media
          // query, use the text replace option, and size the icon at 18px/pt
          display: block;
          height: 20px;
          color: black;
          text-decoration: none;
          &::before {
            content: '';
            width: 18px;
            height: 18px;
            background: url('#{$base-theme-path}img/icons/src/plus.svg') no-repeat;
            background-size: cover;
            display: block;
          }
          .open,
          .closed {
            display: none;
          }
        }
      }
    }
    .transcript__chat {
      display: none;
      @include breakpoint($small-down) {
        border-bottom: 1px solid $color-black;
      }
      &--header {
        @include breakpoint($small-down) {
          float: none;
          text-align: center;
          background-color: $color-nude;
          font-family: $main-font-bold;
          padding: 15px 0;
          width: 100%;
        }
      }
      &--lines {
        .transcript-lines {
          //width: 40%;
          //float: left;
          display: none;
          font-size: 15px;
          @include breakpoint($small-down) {
            width: 100%;
            padding: 0 0 25px;
            h3.transcript-subheader {
              font-size: 25px;
              font-weight: bold;
              padding: 8px 0;
            }
          }
          &__header {
            font-size: 11.5px;
            text-transform: uppercase;
            padding: 0 0 9px 2px;
            @include breakpoint($small-down) {
              font-size: 15px;
              border-bottom: none;
              padding: 15px 0;
            }
          }
          &__list {
            @include breakpoint($small-down) {
              padding: 0 15px;
              margin: 15px 0;
            }
          }
          a.email-transcript-link {
            text-decoration: underline;
            margin-bottom: 0;
            display: block;
            letter-spacing: 0;
          }
          li {
            margin-bottom: 10px;
          }
          span {
            line-height: 185%;
            @include breakpoint($small-down) {
              line-height: 25px;
            }
            &.line-by {
              clear: left;
              display: block;
              float: left;
              font-weight: bold;
              margin-right: 2px;
            }
            &.line {
              display: block;
              margin: 0 0 0 15%;
              @include breakpoint($small-down) {
                margin: 0 0 0 22%;
              }
              span br {
                margin-bottom: 25px;
                @include breakpoint($small-down) {
                  margin-bottom: 13px;
                }
              }
            }
          }
          a.view-more {
            position: relative;
            letter-spacing: 0.18em;
            text-transform: uppercase;
            font-size: 11.8px;
            margin: 20px 0 10px;
            text-align: center;
            font-size: 14px;
            font-family: $main-font-bold;
            display: block;
            width: 100%;
          }
          &.half-width {
            display: block;
            width: 40%;
            float: left;
            @include breakpoint($small-down) {
              width: 100%;
              float: none;
            }
          }
          &.full-width {
            display: block;
            width: 100%;
          }
        }
      }
      &--skus {
        //float: right;
        //width: 55%;
        .recommended-skus {
          &__header {
            font-size: 14px;
            margin: 15px 0 5px;
            text-transform: uppercase;
            padding-bottom: 9px;
            float: left;
            @include breakpoint($small-down) {
              padding: 15px 0;
              margin: 15px 0 20px;
            }
          }
          &__links {
            float: right;
            margin: 10px 0 0;
            &-add-all {
              @include link($color-pink, $color-black);
              @include h8;
              float: right;
              margin-bottom: 12px;
            }
            @include breakpoint($small-down) {
              display: none;
            }
          }
          &__list {
            text-align: center;
            overflow: hidden;
            clear: both;
            margin-bottom: 20px;
            min-height: 480px;
            padding: 0 32px;
            @include breakpoint($small-down) {
              padding: 0;
              min-height: auto;
            }
            &.not-active {
              .slick-list,
              .slick-track {
                display: inline-block;
                //min-width: 100%;
              }
            }
            & button.slick-prev {
              top: 25%;
            }
            & button.slick-next {
              height: 50px;
              left: 707px;
              position: absolute;
              width: 50px;
              top: 25%;
              &:before {
                right: auto;
              }
            }
          }
          @include breakpoint($medium-up) {
            &__list > .product-item {
              display: inline-block;
              float: none;
              vertical-align: top;
              width: 25%;
            }
            &__list > .product-item > div {
              //width: 70%;
            }
          }
        }
        &.half-width {
          display: block;
          width: 55%;
          float: right;
          @include breakpoint($small-down) {
            width: 100%;
            float: none;
          }
        }
        &.full-width {
          display: block;
          width: 100%;
        }
      }
    }
  }

  // Sku styles for mobile and pc transcript area products.
  .transcript,
  .recommended-products__category {
    &.expanded {
      & a.view-details-button {
        &::before {
          content: '';
          width: 18px;
          height: 18px;
          background: url('#{$base-theme-path}img/icons/src/minus.svg') no-repeat;
          background-size: cover;
          display: block;
        }
      }
      .recommended-products__products {
        @include breakpoint($small-down) {
          display: block;
        }
      }
    }
    .recommended-products__header {
      text-transform: uppercase;
      &--view-details {
        @include breakpoint($small-down) {
          position: absolute;
          top: auto;
          right: 30px;
          line-height: 38px;
        }
        .view-details-button {
          @include breakpoint($small-down) {
            line-height: 40px;
            text-indent: 0;
            .open {
              display: none;
            }
            .active & {
              .open {
                display: inline;
              }
              .closed {
                display: none;
              }
            }
          }
          // use the "plus" icon, use the before pseudo selector for the actual icon, signify that this isn't a media
          // query, use the text replace option, and size the icon at 18px/pt
          display: block;
          height: 20px;
          color: black;
          text-decoration: none;
          &::before {
            content: '';
            width: 18px;
            height: 18px;
            background: url('#{$base-theme-path}img/icons/src/plus.svg') no-repeat;
            background-size: cover;
            display: block;
          }
          .open,
          .closed {
            display: none;
          }
        }
      }
    }
    .recommended-products__products {
      @include breakpoint($small-down) {
        display: none;
      }
    }
    .product-item {
      margin: 0 25px 0 0;
      max-width: 19em;
      @include breakpoint($small-down) {
        max-width: 100%;
        clear: both;
        margin: 0 15px 30px;
      }
      .product-info {
        &__product {
          float: none;
          width: 100%;
          & .product-image {
            min-height: 310px;
            float: none;
          }
        }
        &__name {
          min-height: 70px;
          text-align: left;
          & a {
            font-size: 15px;
          }
        }
        &__sku-info {
          border-top: 1px solid $color-black;
          border-bottom: 1px solid $color-black;
          padding: 7px 0 2px;
        }
        &__shades,
        &__sizes {
          float: left;
          width: 60%;
        }
        &__shade {
          margin: 0;
        }
        &__price {
          float: left;
          width: 40%;
        }
        &__actions {
          width: 100%;
          margin: 15px 0 0;
        }
        &__button {
          float: right;
          margin: 0 5px;
          & .icon-heart_outline {
            font-size: 18px;
            text-decoration: none;
          }
        }
      }
    }
  }
}
