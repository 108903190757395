.profile-pic-main {
  #profile-pic-img {
    position: relative;
  }
  #profile-pic-name {
    @include h18;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 33px;
    padding: 4px 2px 2px 2px;
    width: 100%;
    text-align: center;
    background: rgba(255, 255, 255, 0.85);
  }
  #profile-pic-button {
    @include clearfix;
    padding-top: 2px;
  }
  #profile-upload-photo,
  #profile-change-photo,
  #profile-delete-photo {
    @include h21;
  }
  #profile-upload-photo,
  #profile-change-photo {
    float: left;
  }
  #profile-delete-photo {
    float: right;
  }
  #profile_pic_overlay {
    position: relative;
    #profile_pic_content {
      background-color: white;
      border: 1px solid $color-gray;
      left: 160px;
      position: absolute;
      top: -205px;
      width: 250px;
      z-index: 1;
      padding: 10px;
      text-align: center;
      h3 {
        margin-bottom: 8px;
      }
      #profile_pic_close {
        text-align: right;
      }
      .caret {
        position: absolute;
        background: url(/media/images/account/arrow-left-profile-pic.png) no-repeat top center;
        top: 69px;
        left: -12px;
        width: 12px;
        height: 100%;
      }
    }
  }
}
